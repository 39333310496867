/* @import './css/navbar.css';
@import './css/banner.css'; */

html {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}
body {
  /* height: 100vh; */
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

label {
  margin-bottom: 0;
}

#root {
  height: 100%;
  --max-width: 1280px;
}

.content-container {
  max-width: calc(var(--max-width) + 20px);
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.content-container-full {
  max-width: var(--max-width);
  padding: 10px 0px;
  width: 100%;
  margin-bottom: 10px;
}

.row-container {
  max-width: var(--max-width);
  width: 100%;
}

.visually-hidden {
  display: none;
}

.wrapper {
  min-height: 65vh;
  flex: 1;
  /* margin-bottom: -100px;
    padding-bottom: 100px; */
}

.carousel .control-dots .dot {
  border-radius: 8px !important;
  width: 35px !important;
  height: 5px !important;
  box-shadow: none !important;
}

button {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

* {
  -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}
.column {
  display: flex;
  flex-direction: column;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.2s ease-out,
    transform 0.4s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
