@import "./_colors";


.footer {
    width: 100%;
    // height: 40rem;
    background-color: $primary;
    box-shadow: 0 -1rem 5rem rgba(68, 68, 68, 0.2);
}

.quarter {
    width: 25%;
    align-items: center;
}

.footer-subheading, .footer-subheading-div {
    font-size: 2.5rem;
    color: $fourth;
    transition: 0.3s;
}
.footer-subheading:hover {
    color: $fourth-hover;
    text-decoration: none;
}

.footer-link {
    color: $primary-darkest;
    font-size: 1.9rem;
    transition: 0.3s;
}
.footer-link:hover {
    color: $primary-darkest-hover;
    text-decoration: none;
}

.fa-copyright {
    font-size: 1.2rem;
    margin-top: 0.6rem;
    margin-right: 0.2rem;
}

.copyright {
    color: $primary-darkest;
}

.copyright-link {
    color: $primary-darkest;
    transition: 0.3s;
    margin-left: 0.4rem;
}
.copyright-link:hover {
    color: $primary-darkest-hover;
    text-decoration: none;
}

.footer-links {
    font-size: 3rem;
    margin: 1rem;
    color: $primary-darkest;
    transition: 0.5s;
}
.fa-instagram {
    font-size: 3.4rem;
}

.footer-address-link {
    color: $primary-darkest;
    transition: 0.5s;
    font-size: 2.4rem;
}

.open-closed-text {
    color: $primary-darkest;
    font-size: 2.4rem;
    transition: 0.3s
}
.open-closed-text:hover {
    color: $primary-darkest-hover;
    text-decoration: none;
}

.open-closed-text-active {
    color: $fourth;
    font-size: 2.4rem;
    transition: 0.3s;
}

.open-closed-text-active:hover {
    color: $fourth-hover;
    text-decoration: none;
}



.footer-links:hover, .footer-address-link:hover {
    color: $primary-darkest-hover;
    text-decoration: none;
}

.footer-mobile-hide {
    display: flex;
}
.mobile-show {
    display: none;
}

.logo-footer {
    margin: 2rem;
    max-width: 30rem;
    margin-bottom: 1rem;
}

.img-logo {
    width: 100%;
    height: auto;
    display: block;
}

@media only screen and (max-width: 970px) {
  .footer-mobile-hide {
    display: none;
  }
  .mobile-show {
      display: flex;
  }
  .mobile-show-content {
      margin: 2rem;
      font-weight: bold;
  }
  .logo-footer {
    //   max-width: 24rem;
      width: 24rem; 
      margin: 1rem 0; 
  }
}
