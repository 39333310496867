@import "./_colors";

nav {
  position: fixed;
  // top: 0;
  top: 3rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  opacity: 1;
  width: 100%;
  height: 10rem;
  align-items: center;
  border-bottom: 0.5rem solid $primary;
  transition: all 0.5s;
  z-index: 2;
  box-shadow: 0 1rem 5rem rgba(68, 68, 68, 0.1);
}

.nav-container {
  max-width: calc(var(--max-width) + 20px);
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.pre-nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  background-color: $primary;
}

.pre-nav-link {
  color: $primary-darkest;
  font-size: 1.9rem;
  transition: 0.3s;
}
.pre-nav-link:hover {
  color: $primary-darkest-hover;
  text-decoration: none;
}

.pre-nav-web {
  display: block;
}
.pre-nav-mobile {
  display: none;
}

.logo-container {
  margin-left: 3%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 22rem;
  max-width: 100%;
  margin-right: 4rem;
}

.logo {
  height: auto;
  width: 22rem;
}

.nav-row {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin-right: 3%;
  height: 10rem;
}

.nav-item {
  margin: 1rem;
  color: $grey;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.8rem;
  border: 0.5rem solid transparent;
  transition: 0.3s;
  padding-bottom: 4rem;
  padding-top: 4rem;
}
.nav-button {
  margin: 1rem;
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.8rem;
  background-color: $primary;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: 0.3s;
}

.nav-button-mobile {
  color: white;
  /* min-width: 100%; */
  width: 100%;
  max-width: 100%;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.8rem;
  background-color: $primary;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: 0.3s;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
}

.nav-button:hover,
.nav-button-mobile:hover {
  background-color: $primary-hover;
  transition: 0.3s;
  text-decoration: none;
  color: white;
}

.nav-item:hover {
  transition: 0.3s;
  color: $secondary-hover;
}

.nav-toggle {
  display: none;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  position: absolute;
  /* display: none; */
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  left: 0;
  min-width: 16rem;
  padding: 1rem;
  z-index: 1;
  background-color: #fff;
  border: 0.1rem solid #ebebeb;
  margin: 0;
  margin-right: 1rem;
  margin-top: 3.9rem;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: 0.2s;
  font-weight: 500;
  -prefixes-transition-property: opacity, visibility;
  -prefixes-transition-duration: 0.4s, 0s;
  -prefixes-transition-delay: 0s, 0.4s;
}

.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content-small {
  /* display: block; */
  transition: 0.2s;
  visibility: visible;
  opacity: 1 !important;
  -prefixes-transition-delay: 0s, 0s;
}
.dropdown-column {
  display: flex;
  flex-direction: column;
}

.dropdown-link {
  color: $grey;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.7rem;
  transition: 0.3s;
  margin: 0.5rem;
}

.dropdown-link:hover {
  color: $grey-hover;
  transition: 0.3s;
}

aside {
  position: fixed;
  width: 100%;
  min-width: 100%;
  height: 100%;
  z-index: 10000;
  overflow: auto;
  background-color: #fff;
  transform: translateY(200rem);
  transition: all 0.5s;
}

aside.dropdown-mobile {
  transform: translateY(0rem);
}

.dropdown-mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.align-right {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}
.fa-times {
  font-size: 3.5rem;
  // margin: 1.5rem;
  color: $primary;
  cursor: pointer;
  transition: 0.3s;
}
.fa-times:hover {
  color: $primary-hover;
}

.dropdown-mobile-container {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.book-button-container {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.dropdown-mobile-nav-link {
  font-size: 2.6rem;
  text-decoration: none;
  color: $grey;
  margin-bottom: 1rem;
}

.dropdown-mobile-nav-link:hover {
  color: $grey-hover;
}
.dropdown-mobile-nav-link-indented {
  margin-left: 1.5rem;
  font-size: 1.7rem;
}

@media only screen and (max-width: 970px) {
  .nav-row {
    display: none;
  }
  .nav-toggle {
    display: flex;
    padding-right: 3%;
    font-size: 3rem;
    color: $primary;
    text-shadow: -0.5px -0.5px 0 $primary-hover, 0.5px -0.5px 0 $primary-hover,
      -0.5px 0.5px 0 $primary-hover, 0.5px 0.5px 0 $primary-hover;
    transition: 0.4s;
  }
  .nav-toggle:hover {
    color: $primary-hover;
    transition: 0.4s;
  }
}

@media only screen and (max-width: 700px) {
  .pre-nav-web {
    display: none;
  }
  .pre-nav-mobile {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
}
