@import "./_colors";


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.img-special {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 50px;
  border: 0.4rem solid $primary;
  margin-right: 1rem;
  filter: brightness(90%);
}

.specials-subheading {
  font-size: 2rem;
  text-align: center;
  color: $primary-light;
  line-height: 2.1rem;
  padding-bottom: 10px;
}

.specials-container {
  padding-top: 20px;
  padding: 0 5px;
  overflow: auto;
  padding-bottom: 10px;
  display: flex;
  margin: auto;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
}

.modal-title {
  line-height: 55px;
  margin: 0 10px;
}

.modal-row {
  min-width: 31rem;
  max-width: 80rem;
  width: 70rem;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  color: $secondary;
}
.times-margin {
  margin: 1rem;
}
@media only screen and (max-width: 957px) {

  .modal-row {
    width: 65rem;
    font-size: 1.9rem;
  }
  .img-special {
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
  }
  .times-margin {
    margin: 0.5rem 0.5rem 0 0;
  }
}
@media only screen and (max-width: 857px) {
  .modal-row {
    width: 53rem;
    font-size: 1.9rem;
  }

}
@media only screen and (max-width: 707px) {
  .modal-row {
    width: 34rem;
    font-size: 1.6rem;
  }
  .img-special {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }
  .times-margin {
    margin: 0.5rem -0.5rem 0 0;
  }
}
