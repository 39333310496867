@import "./_colors";


.info-screen-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "main sidecar";
  column-gap: 2rem;
}

.info-screen-main {
  grid-area: main;
  justify-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
//   align-items: center;
}

.info-screen-side {
  grid-area: sidecar;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


@media only screen and (max-width: 977px) {
  .info-screen-container {
  grid-template-columns: 1fr;
  grid-template-areas: "main";
  column-gap: 0;
  }
  .info-screen-side {
      display: none;
  }
  .info-screen-main {
      width: 100%;
  }
}