$primary: #F16681;
$primary-hover: #e78497;
$primary-disabled: rgba(231, 132, 152, 0.524);
$primary-light: #4d4d4d;
$primary-alt: #313131;
$primary-dark: #fff;
$primary-dark-hover: #dadada;
$primary-darkest: #fff;
$primary-darkest-hover: #dadada;
$secondary: #f6a0a4;
$secondary-light: #f9babe;
$secondary-hover: #303030;
$secondary-hover-alt: #303030;
$third: rgb(205, 193, 193);
$third-hover: rgb(180, 180, 177);
$fourth: #ffffff;
$fourth-hover: #b3b3b4;

$grey: #5a5a5a;
$grey-hover: #404040;

$text: #444444;