@import "./_colors";

.banner {
  width: 100%;
  max-width: 100%;
  opacity: 0.8;
  height: 76vh;
  object-fit: cover;
}

.aging-face-container {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: $primary-alt;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3%;
  padding-right: 3%;
  color: $primary-darkest;
}

.aging-face-container-full {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3%;
  padding-right: 3%;
  display: block;
  position: relative;
}

.contain-aging {
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
}

.subheading-aging {
  color: $primary-dark;
  font-size: 2.3rem;
  text-align: center;
}


.aging-face-container-full::after {
  background: url("../images/aging.jpg") center center no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.col-40 {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.col-60 {
  width: 65%;
}
.col-45 {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.col-55 {
  width: 60%;
  color: $secondary;
}

.aging-image {
  width: 30rem;
  max-width: 100%;
  padding: 1rem;
  //   height: auto;
  border-radius: 15px;
  box-sizing: border-box;
  height: 27rem;
  object-fit: cover;
}
.aging-image-container {
  width: 30rem;
  max-width: 100%;
  height: 27rem;
  border-radius: 10px;
  background-color: $primary;
}
.headshot-image {
  width: 25rem;
  max-width: 100%;
  padding: 1rem;
  //   height: auto;
  border-radius: 15px;
  box-sizing: border-box;
  height: 35rem;
  object-fit: cover;
}
.headshot-image-container {
  width: 25rem;
  max-width: 100%;
  border-radius: 10px;
  height: 35rem;
  margin-right: 1rem;
  float: left;
  background-color: $third-hover;
}
.reviews-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  // min-height: 56rem;
  background-color: #fff;
}
.reviews-container-background {
  background-color: $primary;
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
}

.reviews-card-container {
  background-color: $primary-hover;
  border: 0.1rem solid $primary;
  min-height: 27rem;
  width: 100%;
  min-width: 100%;
  border-radius: 10px;
  // box-shadow: 0 0 3rem rgba(68, 68, 68, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.review-text,
.review-text-shortened {
  margin: 1rem 2rem;
  color: $primary-dark;
  font-size: 2rem;
}

.review-text-shortened {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.reviews-header {
  color: $primary-dark;
}

.review-info-div {
  display: block;
  margin-left: 2rem;
  margin-right: 2rem;
  color: $primary-dark;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.fa-yelp,
.fa-google {
  font-size: 3.3rem;
  color: $primary-dark;
  transition: 0.3s;
  margin: 0 1.5rem;
}

.fa-yelp:hover,
.fa-google:hover {
  color: $primary-hover;
}
.link-review {
  color: $primary-dark;
  transition: 0.3s;
  text-decoration: underline;
}
.link-review:hover {
  color: $primary;
  text-decoration: underline;
}

.arrow-button {
  color: $primary-hover;
  transition: 0.3s;
}
.arrow-button:hover {
  color: #f5ffc2;
  cursor: pointer;
}

.arrow-button:disabled {
  color: rgba(68, 68, 68, 0.13);
  cursor: auto;
}

.reviews-margin {
  padding-top: 4rem;
}
.reviews-margin-large {
  padding-top: 1rem;
}

.card-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.9rem;
  color: $secondary;
  pointer-events: none;
  // font-weight: bold;
}
.card-text-alt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.9rem;
  color: #fff;
  // font-weight: bold;
}

@media only screen and (max-width: 977px) {
  .aging-face-container > h1 {
    text-align: center;
  }
  .heading-small {
    font-size: 3rem;
  }
  .col-40,
  .col-60,
  .col-45,
  .col-55 {
    width: 33rem;
    max-width: 100%;
  }
  .headshot {
    max-width: 31rem;
    height: auto;
  }
  .aging-image {
    max-width: 31rem;
    // height: auto;
  }
  .reviews-container {
    min-height: auto;
  }
  .reviews-card-container {
    min-height: 36rem;
  }
  .review-text,
  .review-text-shortened {
    font-size: 1.8rem;
  }
  .review-info-div {
    margin-bottom: 0;
  }
  .reviews-header {
    font-size: 2.5rem;
  }
  .reviews-margin {
    padding: 2rem 0;
    margin: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .headshot-image {
    width: 25rem;

    height: 30rem;
    object-fit: cover;
  }
  .headshot-image-container {
    width: 25rem;

    height: 30rem;
    margin-right: 1rem;
    float: left;
    background-color: $third-hover;
  }
}
@media only screen and (max-width: 1100px) {
  .headshot-image {
    width: 23rem;

    height: 27rem;
    object-fit: cover;
  }
  .headshot-image-container {
    width: 23rem;

    height: 27rem;
    margin-right: 1rem;
    float: left;
    background-color: $third-hover;
  }
}
@media only screen and (max-width: 1050px) {
  .headshot-image {
    width: 21rem;

    height: 25rem;
    object-fit: cover;
  }
  .headshot-image-container {
    width: 21rem;

    height: 25rem;
    margin-right: 1rem;
    float: left;
    background-color: $third-hover;
  }
}

@media only screen and (max-width: 1000px) {
  .headshot-image {
    width: 31rem;

    height: 35rem;
    object-fit: cover;
  }
  .headshot-image-container {
    width: 31rem;

    height: 35rem;
    margin-right: 1rem;
    float: left;
    background-color: $third-hover;
  }
}
