@import "./_colors";


.contact-form-container {
    background-color: $fourth;
    border-radius: 10px;
    padding: 1rem;
      box-shadow: 0 0 2rem rgba(68, 68, 68, 0.2);
    width: 100%;
}

.label-input {
    margin: 0.4rem 0;
}

input {
    padding: 0.5rem;
    border-radius: 5px;
    border: 0.1rem solid $fourth-hover;
}


input:focus {
    outline: none;
}

textarea {
    padding: 0.5rem;
    border-radius: 5px;
    border: 0.1rem solid $fourth-hover;
    resize: none;

}

.submit-button {
    padding: 1rem;
    border-radius: 5px;
    background-color: $primary;
    color: #fff;
    transition: 0.4s;
    width: 100%;
    cursor: pointer;
}

.submit-button:disabled {
    background-color: $primary-disabled;
    opacity: 0.7;
    color: $fourth;
    cursor: not-allowed;
}

.error-message {
    color: red;

}

.success-message {
    color: green;
    text-align: center;
}

.contain-form {
    max-width: 70rem;
    margin: auto;
    // min-height: 30rem;
}