@import "./_colors";



html, body {
   width:100%;
   max-width: 100%;
   margin:0; 
   padding: 0;
//    overflow-x: hidden;
//    overflow-y: auto;
 }

h1 {
  font-size: 4rem;
  color: $primary;
  margin-bottom: 1rem;
  text-align: center;
}
h2 {
  font-size: 3rem;
  color: $primary;
  margin-bottom: 1rem;
}
h3 {
    font-size: 2.3rem;
    color: $primary;
    margin-bottom: 1rem;
}
.left-align {
    text-align: left;
    float: left;
}
.pdf {
  width: 800px;
  height: 1000px;
}
.pdf-mobile {
    display: none;
}

.text-med {
    font-size: 1.8rem;
    color: $text;
}
.card-img {
  transition: 0.2s;
}
.card:hover .card-img {
    webkit-filter: blur(4px); /* Chrome, Safari, Opera */
    filter: blur(4px);
}


.hours-row {
  min-width: 31rem;
  width: 80rem;
  max-width: 60rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: $primary-light;
}

// card
.card {
  min-width: 31rem;
  max-width: 31rem;
  width: 31rem;
  overflow: hidden;
  height: 20rem;
  border-radius: 20px;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3rem rgba(68, 68, 68, 0.13);
  margin: 1rem;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.05);
  transform-origin: center;
}
.contain {
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
  color: $secondary;
}

.directions-button {
  appearance: button;
  background-color: $primary;
  padding: 1rem;
  color: #fff;
  border-radius: 10px;
  transition: 0.3s;
  margin-top: 1rem;
  cursor: pointer;
}
.directions-button:hover {
  background-color: $primary-hover;
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
}
.contain-small {
  max-width: 70rem;
}
.med-text {
  font-size: 1.8rem;
  color: $secondary-hover-alt;
}
.subheading {
  color: $text;
  font-size: 2.3rem;
  text-align: center;
}
.bold {
  font-weight: 600;
}

.book-button-full {
  width: 100%;
  max-width: 100%;
  height: 7rem;
  background-color: $primary;
  color: #fff;
  font-size: 3rem;
  cursor: pointer;
  transition: 0.3s;
}
.book-button-full:hover {
  background-color: $primary-hover;
  cursor: pointer;
}

.specials-button-full {
  width: 100%;
  max-width: 100%;
  height: 7rem;
  background-color: $secondary;
  color: #fff;
  font-size: 3rem;
  cursor: pointer;
  transition: 0.3s;
}
.specials-button-full:hover {
  background-color: $secondary-light;
  cursor: pointer;
}
.green-text {
    color: $primary;
}


.p-text {
    font-size: 1.8rem;
}
.button-more {
    background-color: $primary;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 1rem;
    min-width: 10rem;
    transition: 0.3s;
}
.button-more:hover, .button-green:hover {
    background-color: $primary-hover;

}
.button-green {
    background-color: $primary;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 0.5rem;
    min-width: 10rem;
    transition: 0.3s;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    width: 324px;
}

.fa-chevron-left, .fa-chevron-right {
    font-size: 4rem;
}

.align-center {
    align-items: center;
    justify-content: space-between;
}
.row-fixed {
    display: flex;
    flex-direction: row;
}
.fa-star {
    color: gold;
}

.caption {
    font-size: 7rem;
    color: $primary-hover;

    font-weight: bold;
    background-color: #eee;
    border-radius: 20px;
    opacity: 0.2;
}

.link {
  color: $primary;
  transition: 0.3s;
  font-size: 2rem;
}

.link:hover {
    color: $primary-hover;
    text-decoration: none;
}

.day-active {
    color: $primary;
}

.is-open {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}

.middle-align {
      text-align: center;

}

.maps-icons {
    opacity: 1;
    transition: 0.3s;
    margin: 1rem;
}
.maps-icons:hover {
    opacity: 0.7;
}

.image {
    width: 100%;
    border-radius:1rem;
    opacity: 0.9;
    max-height: 400px;
    object-fit: cover;
}
.image-tour {
  max-width: 80%;
  max-height: 70rem;
  border-radius:1rem;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}
.fade-in-fast {
    opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
}




@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}



@media only screen and (max-width: 977px) {
  .pdf {
    width: 100%;
    max-width: 100%;
    height: 500px;
  }
  .contain {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hours-row {
    width: 33rem;
    font-size: 1.9rem;
  }
  .p-text {
    font-size: 1.7rem;
}
  .subheading {
      font-size: 2.2rem;
  }

 
  .contain-small {
      margin: 0 2rem;
      text-align: left;
  }
  
}

@media only screen and (max-width: 1100px) {
 .pdf-mobile {
      display: block;
      text-align: center;
      margin-top: 2rem;
  }

}
