@import "./_colors";

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.row-full,
.row-welcome {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.two-col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 900px) {
  .two-col-grid {
    display: block;
  }
}

.row-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 900px;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.col-half {
  width: 50%;
}
.headshot {
  width: 30rem;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  // filter: grayscale(90%);
  // box-shadow: 0 1rem 3rem rgba(68, 68, 68, 0.3);
}

.col-one {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.col-two {
  width: 65%;
  color: $primary-alt;
}
.about-short-container {
  display: flex;
  width: 100%;
  max-width: 100%;
  background-color: $third;
  color: $secondary-hover-alt;
}
.about-short-container-mobile {
  display: none;
}
.about-text {
  font-size: 1.7rem;
}

@media only screen and (max-width: 977px) {
  .row {
    flex-direction: column;
    align-items: center;
  }
  .col-one,
  .col-two {
    width: 31rem;
    max-width: 100%;
  }

  .headshot {
    max-width: 31rem;
    height: auto;
  }
  .row-full {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
}

@media only screen and (max-width: 1000px) {
  .row-welcome {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    align-items: center;
  }
  .col-half {
    width: 100%;
    max-width: 100%;
  }
  .about-short-container {
    display: none;
  }
  .about-short-container-mobile {
    display: block;
    width: 100%;
    max-width: 100%;
    background-color: $third;
    height: auto;
  }
  .about-short-mobile-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 31rem;
    margin-left: auto;
    margin-right: auto;
  }
}
